
import { computed, defineComponent, ref, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import {
  CourseCateActions,
  CourseCateGetters,
} from "@/store/vm/course-cate/enums";
import { StepResult } from "@/store/captain/CaptainModule";

export default defineComponent({
  name: "upsert-course-cate",
  components: {},
  props: {
    courseCateId: {
      type: [Number, Boolean],
      required: true,
      default: false,
    },
  },
  emits: ["reset"],
  setup(props, { emit }) {
    const store = useStore();

    const formRef = ref<null | HTMLFormElement>(null);
    const courseCateUpsertModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const defaultData = {
      cate_id: "",
      name: "",
      desp: "",
      status: 0,
    };

    const formData = ref({ ...defaultData });

    const modalUpsertId = computed(() => {
      return store.state.CourseCateModule.modalUpsertId;
    });

    const statusLabels = [
      {
        value: 0,
        label: "Active",
      },
      {
        value: 1,
        label: "Inactive",
      },
    ];

    const rules = ref({
      name: [
        {
          required: true,
          message: "Yêu cầu nhập tên",
          trigger: "change",
        },
      ],
    });

    const isCreate = computed(() => {
      return !props.courseCateId;
    });

    const courseCateIdTarget = computed(() => {
      return props.courseCateId;
    });

    watch(courseCateIdTarget, (currentValue) => {
      if (currentValue) {
        // update
        const detail = store.getters[CourseCateGetters.GET_BY_ID](currentValue);
        formData.value = detail;
      } else {
        reset();
      }
    });

    const reset = () => {
      formData.value = defaultData;
      emit("reset");
    };

    const actionCreate = (par) => {
      return store.dispatch(CourseCateActions.CREATE, par).catch((e) => {
        console.log(e);
      });
    };

    const actionUpdate = async (par) => {
      return await store.dispatch(CourseCateActions.UPDATE, par).catch((e) => {
        console.log(e);
      });
    };

    const submit = async () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          let stepResult: StepResult;
          if (props.courseCateId) {
            stepResult = await actionUpdate(formData.value);
          } else {
            stepResult = await actionCreate(formData.value);
          }

          loading.value = false;
          if (stepResult.isSuccess) {
            hideModal(courseCateUpsertModalRef.value);
            Swal.fire({
              position: "top-end",
              text: "Success!",
              icon: "success",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
            reset();
          } else {
            Swal.fire({
              position: "top-end",
              text: "Đã xảy ra lỗi, vui lòng thử lại sau!",
              icon: "error",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        } else {
          Swal.fire({
            text: "Định dạng dữ liệu không đúng, vui lòng kiểm tra lại biểu mẫu",
            icon: "error",
            buttonsStyling: false,
            timer: 2000,
            width: 400,
            showCancelButton: false,
            showConfirmButton: false,
          });
          return false;
        }
      });
    };

    return {
      modalUpsertId,
      isCreate,
      defaultData,
      formData,
      submit,
      formRef,
      rules,
      loading,
      courseCateUpsertModalRef,
      courseCateIdTarget,
      statusLabels,
    };
  },
});
