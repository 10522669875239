
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import List from "@/views/course-cate/List.vue";
import Upsert from "@/views/course-cate/Upsert.vue";

import { defineComponent, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import {
  CourseCateActions,
  CourseCateGetters,
} from "@/store/vm/course-cate/enums";

interface ComponentData {
  courseCateIdModal: number | boolean;
}
export default defineComponent({
  name: "courseCate",
  props: {
    widgetClasses: String,
  },
  components: {
    List,
    Upsert,
  },
  data(): ComponentData {
    return {
      courseCateIdModal: false,
    };
  },
  methods: {
    onUpsert(courseCateId: number | boolean) {
      this.courseCateIdModal = courseCateId;
    },
    onReset() {
      this.courseCateIdModal = false;
    },
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Danh mục chương trình học", ["Đào tạo"]);
      MenuComponent.reinitialization();
    });

    const onDel = (courseCateId) => {
      const detail = store.getters[CourseCateGetters.GET_BY_ID](courseCateId);

      Swal.fire({
        title: "Bạn muốn xóa danh mục chương trình học?",
        text: detail.name || "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const resDel = await actionDelete(courseCateId);
          console.log(resDel);
        }
      });
    };

    const actionDelete = async (courseCateId) => {
      return store
        .dispatch(CourseCateActions.DELETE, courseCateId)
        .catch((e) => {
          console.log(e);
        });
    };

    return {
      onDel,
    };
  },
});
